/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@import '~antd/dist/antd.css';
@import '~nprogress/nprogress.css';

html,body {
  background: #f0f2f5;
}

.no-wrap {
  /* 文本不换行 */
  white-space: nowrap;
}

.ant-table-thead .ant-table-cell {
  white-space: nowrap;
}
